<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Patient Questionnaire
        </div>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-btn color='primary' v-show='allowAdd' @click='createDlg'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          New
        </v-btn>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-layout row wrap>
          <div class='filterStyle'>
            <v-text-field label='Patient Id:' v-model='patientId'>
              <template slot='prepend'>
                <v-icon class='filterIcon grey--text text--darken-1'
                >fa fa-filter</v-icon
                >
              </template>
            </v-text-field>
          </div>
          <v-flex>
            <v-btn :disabled='loading' @click='searchPatient'>
              <v-icon class='iconText'>fa fa-search</v-icon>
              Search
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6 sm6 lg6 v-show='patient.Id'>
        <PatientCard :patient='patient'></PatientCard>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-card>
          <v-card-text class='pa-0'>
            <PatientQuestionnaireDisplay
                :items='items'
                :loading='loading'
                @view='viewDlg'
            ></PatientQuestionnaireDisplay>
            <ActionDialogue
                :patient='patient'
                :dlg-show='dlgShow'
                :dlg-type='dlgType'
                :selected-item='selectedItem'
                @close='closeDlg'
                @add='getRelatedData'
            />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PatientCard from '../../components/patients/PatientCard';
import PatientQuestionnaireDisplay from '../../components/patient_questionnaire/List.vue';
import ActionDialogue from '@/components/patient_questionnaire/ActionDialogue.vue';
import WebH from '../../api/web';
export default {
  components: {
    PatientCard,
    PatientQuestionnaireDisplay,
    ActionDialogue
  },
  created() {
    this.allowAdd = this.$store.getters['UserStore/permissions'].includes(
        'pat.v'
    );
    this.patientId = this.$store.getters['SettingsStore/patientId'];
    this.searchPatient();
  },
  data() {
    return {
      allowAdd: false,
      loading: false,
      items: [],
      patientId: '',
      patient: {},
      dlgShow: false,
      dlgType: 0,
      selectedItem: null
    };
  },
  methods: {
    closeDlg() {
      this.selectedItem = null;
      this.dlgShow = false;
    },
    viewDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 1;
      this.dlgShow = true;
    },
    createDlg() {
      this.selectedItem = this.items.length > 0 ? Object.assign({}, this.items[0]) : null;
      this.dlgType = 0;
      this.dlgShow = true;
    },
    async searchPatient() {
      if (this.patientId === '') {
        return;
      }
      this.$store.commit('SettingsStore/setPatientId', this.patientId);
      this.loading = true;
      this.patient = {};
      this.exams = [];
      let url = '/patients/search?&name=&pid=' + this.patientId + '&mobile=';
      const res = await WebH.Get(url, 'patients');
      if (res.Success) {
        if (res.Data.length > 0) {
          this.patient = res.Data[0];
          await this.getRelatedData();
        } else {
          await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        }
      }
      this.loading = false;
    },
    async getRelatedData() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.items = [];
      let url = '/allergies/' + this.patient.Id + '/all';
      const res = await WebH.Get(url, 'patient questionnaire');
      if (res.Success) {
        this.items = res.Data;
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>