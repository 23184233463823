<template>
  <v-dialog v-model='dlgShow' scrollable persistent max-width='80%'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          {{ dlgType === 0 ? 'Add' : 'View' }} patient questionnaire
        </div>
      </v-card-title>
      <v-card-text>
        <AllergySurveyComp
            ref='allergyComp'
            :problemData='selectedItem'
        ></AllergySurveyComp>
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-item>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn v-if='dlgType === 0'
            color='primary darken-1'
            text
            :loading='submit.working'
            @click='saveDlg'
        >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AllergySurveyComp from '../../components/forms/AllergySurveyV1';
import WebH from '@/api/web';
export default {
  components: {
    AllergySurveyComp
  },
  props: {
    patient: { type: Object, default: () => {}},
    dlgShow: { type: Boolean, default: false },
    dlgType: {
      type: Number,
      default: 0
    },
    selectedItem: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      }
    };
  },
  methods: {
    closeDlg() {
      this.errors.clear();
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.$emit('close');
    },
    async saveDlg() {
      if (!this.patient || (this.patient.PatientId  < 1 && this.dlgType === 0)) {
        await this.$notifyDlg.show('Invalid patient', 'Error', 'error');
        return;
      }
      if (this.dlgType === 0) {
        const vModel = {
          Problems: this.$refs.allergyComp.getData(),
          PatientId: this.patient.PatientId,
          Mobile: this.patient.Mobile
        };

        await this.api_createAllergyForm();
        this.submitForm(vModel);
      }
    },
    async api_createAllergyForm() {
      this.loading = true;
      const res = await WebH.Get1(
          '/Allergies/' + this.patient.Id + '/create',
          ''
      );
      this.loading = false;
      return !!res.Success;
    },
    async submitForm(data) {
      this.submit.working = true;
      const res = await WebH.Post('/allergies', data);
      this.submit.working = false;
      if (res.Success) {
        await this.$notifyDlg.show(
            'Answers submitted. Thank you',
            'Info',
            'info'
        );
        this.$store.commit('FormsStore/reset');
        this.$refs.allergyComp.clear();
        this.$emit('add');
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
    }
  }
}
</script>

<style scoped>

</style>